import axios from 'axios'
import JsCookie from 'js-cookie'
import { URL } from '@common/helpers'
import { store } from '../store/index'
import _ from 'lodash'

/* axios config https://github.com/axios/axios*/

// axios.defaults.transformRequest = [
//   (data, headers) => {
//     if (Object.prototype.toString.call(data) === '[object Object]') {
//       data = ObjToString(data).substring(1)
//     }
//     return data
//   }
// ]

axios.defaults.transformResponse = [
  (data) => {
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  },
]

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    /**
     * /capi/openpay/* 接口需要请求头
     * **/
    if (config.url.indexOf('/capi/openpay') > -1) {
      // 请求头信息
      const headerConfig = getRequestHeaderConfig()
      Object.keys(headerConfig).forEach((key) => {
        if (notEmpty(headerConfig[key])) {
          config.headers = Object.assign(config.headers, {
            [key]: headerConfig[key],
          })
        }
      })
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  (error) => {
    let errData = {}
    if (error.response) {
      const { status } = error.response
      if (status === 401) {
        // 如果是非登录页 就返回到登录页 如果是就不需要管了
        const { pathname } = window.location
        const isLoginPage =
          pathname === '/open-pay/signin' || pathname === '/open-pay/pc/signin'

        const orderListPage = '/open-pay/order-list'
        if (pathname === orderListPage) return // 站内订单列表页不需要openpay登录态
        if (!isLoginPage) {
          const loginUrl =
            pathname.indexOf('/pc/') > -1
              ? '/open-pay/pc/signin'
              : '/open-pay/signin'
          location.replace(`${loginUrl}${window.location.search}`)
          return convertErrorResponse('unauthorized')
        }
      }
    }
    if (error.response) {
      const { status, statusText } = error.response
      errData = convertErrorResponse(status, statusText)
    } else {
      // console.error(error)
      errData = convertErrorResponse('Unknown Error')
    }

    //将所有的异常resolve掉
    return Promise.resolve(errData)
  },
)

function convertErrorResponse(errStatus, errMsg) {
  const errCode = `FE.${errStatus}`
  if (!errMsg) errMsg = errCode

  return {
    data: {
      success: false,
      errCode: `${errCode}`,
      errMsg: `${errMsg}`,
      data: undefined,
      sysTime: 0,
    },
  }
}

// 获取设备id
function getDeviceData() {
  const mainState = store.getState()
  const deviceData = mainState.getIn(['main', 'deviceData'])

  if (deviceData && !deviceData.isEmpty()) {
    const deviceType = (deviceData.getIn(['os']) || '').toLowerCase()
    const deviceId = deviceData.getIn(['device_id']) || ''
    const longitude = deviceData.getIn(['location', 'longitude'])
    const latitude = deviceData.getIn(['location', 'latitude'])
    let params = {
      'device-type': deviceType,
      'device-id': deviceId,
    }
    if (latitude) {
      Object.assign(params, { latitude })
    }
    if (longitude) {
      Object.assign(params, { longitude })
    }
    return params
  }
  return {}
}

// 非空判断
function notEmpty(val) {
  return val !== undefined && val !== null && val !== ''
}

// 是否是pc端
function isPc() {
  return window.location.href.indexOf('/pc/') > -1
}

// 页面类型 pc 或者 h5
const PAGE_TYPE = {
  PC: 2,
  APP: 1,
}

/**
 * 订单信息
 *  X_MERCHANT_ID X_REF_NO X_SIGN字段（分别对应 appId, refNo, sign
 * */
function getOrderData() {
  const { appId, refNo, sign } = URL.allParam()
  return {
    'X-MERCHANT-ID': appId,
    'X-REF-NO': refNo,
    // eslint-disable-next-line no-control-regex
    'X-SIGN': _.replace(sign, /[^\x00-\x7F]/g, ''),
  }
}

// 印尼的语言id
const ID_LANGUAGE_ID = 123
// 获取请求头配置
function getRequestHeaderConfig() {
  const deviceData = getDeviceData()
  const orderData = getOrderData()
  const pageType = isPc() ? PAGE_TYPE.PC : PAGE_TYPE.APP
  // 获取语言码id 默认为印尼的123
  const languageId = JsCookie.get('languageId') || ID_LANGUAGE_ID
  const xUserCountryId = JsCookie.get('countryId') || ''

  return {
    ...deviceData,
    ...orderData,
    'page-type': pageType,
    'X-REQ-TIME': new Date().toISOString(),
    'language-id': languageId,
    'X-user-country-id': xUserCountryId,
  }
}
