import { actions } from './index'
import { helpers } from '@common'
import { shopeeAppIds, lazadaAppIds } from '../../../common/macro'

const __addBasicOrderInfo = (basicState) => ({
  type: actions.ADD_BASIC_ORDER_INFO,
  basicState,
})

export const goInitBasicOrderInfo = (...argv) => {
  const queryString = window.location.search
  const fromShopee = shopeeAppIds.includes(argv[0].appId) //是否来自shopee
  const fromLazada = lazadaAppIds.includes(argv[0].appId) //是否来自lazada
  const os = navigator.platform,
    userAgent = navigator.userAgent
  let deviceType = 6 //设备类型。4 表示android, 5 表示ios, 6 表示pc

  if (helpers.isIOS()) {
    deviceType = 5
  } else if (helpers.isAndroid()) {
    deviceType = 4
  }

  return (dispatch) => {
    dispatch(
      __addBasicOrderInfo({
        ...argv[0],
        fromShopee,
        fromLazada,
        os,
        userAgent,
        deviceType,
        queryString,
      }),
    )
  }
}

export const goAddFingerprint = (fingerprint) => ({
  type: actions.ADD_FINGERPRINT,
  fingerprint,
})

// 是否从老系统过来
export const goSetJumpFromOld = (jumpFromOld) => ({
  type: actions.SET_JUMP_FROM_OLD,
  jumpFromOld,
})
