import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router/immutable'

import { asyncComponent } from '@common'
// import PaymentMethod from '../views/paymentMethod'
// import OrderDetails from '../views/orderDetails'
// import ApplyInstruction from '../views/applylimit-instruction'

import { history } from '../store'
import Main from '../views/main'
import { dynamic } from 'umi'
import { bridge } from '~/common/bridge'
import { prerenderDynamics } from '@/__prefix__/prerenders'
import CacheRoute from 'react-router-cache-route'

const PaymentMethod = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/PaymentMethod' */ '../views/paymentMethod'
  ),
)
const ApplyInstruction = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/ApplyInstruction' */ '../views/applylimit-instruction/new'
  ),
)
const DownloadApp = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/DownloadApp' */ '../views/applylimit-instruction/download-app'
  ),
)
const ApplylimitInstructionInApp = dynamic(() =>
  import(
    /* webpackChunkName: 'openPay/ApplylimitInstructionInApp' */ '../views/applylimit-instruction/App'
  ),
)

const ApplyLimits = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/ApplyLimits' */ '../views/applyLimits'),
)

const ApplyLimitResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/ApplyLimitResult' */ '../views/applyLimitResult'
  ),
)

// const ApplyLimitInstruction = asyncComponent(() =>
//   import(/* webpackChunkName: 'openPay/ApplyLimitInstruction' */ '../views/applyLimit-instruction')
// )

const Ecommerce = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/Ecommerce' */ '../views/ecommerce'),
)

const PaymentResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/PaymentResult' */ '../views/paymentResult'
  ),
)

// const Register = asyncComponent(() =>
//   import(/* webpackChunkName: 'openPay/Register' */ '../views/register'),
// )

const RegisterNew = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/Register' */ '../views/register-new'),
)

const SetPassword = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/Register' */ '../views/set-password'),
)

const CouponCenter = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/CouponCenter' */ '../views/coupon-center'
  ),
)

const CouponDetail = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/CouponDetail' */ '../views/coupon-center/views/coupon-detail/'
  ),
)

const OrderPending = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/OrderPending' */ '../views/order-pending'
  ),
)

const PaymentContinue = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/PaymentContinue' */ '../views/pay-continue'
  ),
)

const Terms = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/Terms' */ '../views/terms'),
)

// 站内订单列表页
const OrderList = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/orderList' */ '../views/order-list'),
)
// 站内订单列表详情页
const OrderListDetail = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/orderListDetail' */ '../views/order-list-detail'
  ),
)

const ApprovalResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/approvalResult' */ '../views/approvalResult'
  ),
)
const CreateOrder = dynamic(() =>
  import(/* webpackChunkName: 'openPay/CreateOrder' */ '../views/CreateOrder'),
)
const CreateOrderResult = dynamic(() =>
  import(
    /* webpackChunkName: 'openPay/createOrderResult' */ '../views/CreateOrderResult'
  ),
)
const AppPayMiddle = dynamic(() =>
  import(
    /* webpackChunkName: 'openPay/AppPayMiddle' */ '../views/AppPayMiddle'
  ),
)
const CreateOrderFailed = dynamic(() =>
  import(
    /* webpackChunkName: 'openPay/CreateOrderFailed' */ '../views/CreateOrderFailed'
  ),
)
const DesktopPay = dynamic(() =>
  import(/* webpackChunkName: 'openPay/DesktopPay' */ '../views/desktopPay'),
)
const AppDownload = dynamic(() =>
  import(/* webpackChunkName: 'openPay/AppDownload' */ '../views/appDownload'),
)

// const IpVerifyResult = asyncComponent(() =>
//   import(
//     /* webpackChunkName: 'openPay/IpVerifyResult' */ '../views/IpVerifyResult'
//   ),
// )

const SystemMaintenance = dynamic(() =>
  import(
    /* webpackChunkName: 'openPay/SystemMaintenance' */ '../views/system-maintenance'
  ),
)

const SecurityPage = dynamic(() =>
  import(
    /* webpackChunkName: 'openPay/SecurityPage' */ '../views/SecurityPage'
  ),
)

export const AppRouter = () => (
  <ConnectedRouter history={history}>
    <Main>
      <Route
        exact
        path="/openPay.html"
        render={() => <Redirect to={`/login${window.location.search}`} />}
      />
      <Route
        exact
        path="/login"
        render={() => <Redirect to={`/signin${window.location.search}`} />}
      />
      <Route exact path="/signin" component={prerenderDynamics.new.Login} />{' '}
      <Route exact path="/register" component={RegisterNew} />
      <Route exact path="/set-password" component={SetPassword} />
      <Route
        exact
        path="/applylimit-instruction"
        component={
          bridge.isAkulaku ? ApplylimitInstructionInApp : ApplyInstruction
        }
      />
      <Route exact path="/download-app" component={DownloadApp} />
      <Route
        exact
        path="/orderDetails"
        component={prerenderDynamics.new.OrderDetails}
      />
      <Route path="/paymentMethod/" component={PaymentMethod} />
      <Route path="/applyLimits/:step/:applyStatus" component={ApplyLimits} />
      <Route exact path="/applyLimitResult" component={ApplyLimitResult} />
      <Route exact path="/ecommerce/:path" component={Ecommerce} />
      <Route path="/paymentResult/" component={PaymentResult} />
      <Route path="/pay-continue/" component={PaymentContinue} />
      <Route path="/coupon-center/" exact component={CouponCenter} />
      <Route path="/coupon-center/coupon-detail" component={CouponDetail} />
      <Route path="/order-pending/" exact component={OrderPending} />
      <Route path="/terms/" component={Terms} />
      <CacheRoute
        exact
        path="/order-list/"
        component={OrderList}
        behavior={(cached) =>
          cached
            ? { style: { display: 'none' } }
            : { style: { height: '100%' } }
        }
      />
      <Route path="/order-list-detail/" component={OrderListDetail} />
      <Route path="/approvalResult" component={ApprovalResult} />
      <Route path="/CreateOrder" component={CreateOrder} />
      <Route path="/CreateOrderResult" component={CreateOrderResult} />
      <Route path="/AppPayMiddle" component={AppPayMiddle} />
      <Route
        path="/ip-result"
        exact
        component={prerenderDynamics.new.IpVerifyResult}
      />
      <Route path="/CreateOrderFailed" component={CreateOrderFailed} />
      <Route path="/desktop-pay" component={DesktopPay} />
      <Route path="/app-download" component={AppDownload} />
      <Route path="/system-maintenance" component={SystemMaintenance} />
      <Route path="/security" component={SecurityPage} />
    </Main>
  </ConnectedRouter>
)
