import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import Fingerprint from 'fingerprintjs2'
import { Toast, Modal } from 'antd-mobile'
import { initTracker } from '~/common/tracker/tracker'
import { helpers, withBasic } from '@common'
import Logger from '~/config/logger'
import { actionCreator } from './store'
import { Loading } from '@Arui'
import { history } from '../../store'
import { globalActionCreator } from '~/common/globalActions'
import { countryIdMap } from '~/common/macro'
import { ModalTitle } from '~/components/ModalTitle'
import JsCookie from 'js-cookie'
import _ from 'lodash'
import { initDeviceSDK, getDeviceId } from '@/components/device-sdk'
@withBasic
class Main extends PureComponent {
  render() {
    return (
      <div className="maxHeight" key="2">
        {this.props.children}
      </div>
    )
  }

  handleGlobalTips(prevProps, nextProps) {
    const prevLoading = prevProps.loading.get('show'),
      nextLoading = nextProps.loading.get('show'),
      loadingContent = nextProps.loading.get('content'),
      prevToast = prevProps.toast.get('show'),
      nextToast = nextProps.toast.get('show'),
      prevDialog = prevProps.alertDialog.get('show'),
      nextDialog = nextProps.alertDialog.get('show')

    //控制全局Loading
    if (prevLoading !== nextLoading) {
      nextLoading ? Loading.show(loadingContent) : Loading.hide()
    }

    //控制全局Toast
    if (nextToast && prevToast !== nextToast) {
      const { toast, destroyToast } = nextProps
      const content = toast.get('content'),
        duration = toast.get('duration')

      Toast.info(content, duration, () => destroyToast())
    }

    //控制全局弹窗提示
    if (nextDialog && prevDialog !== nextDialog) {
      const { alertDialog, hideDialog } = nextProps
      const content = alertDialog.get('content')
      const hideFooter = alertDialog.get('hideFooter')
      const isTextNode = typeof content === 'string'

      const title = alertDialog.get('title')
      const closable = alertDialog.get('closable')
      const dialogContent = isTextNode ? content : content.toJS()
      const footer = alertDialog.get('footer')
        ? alertDialog.get('footer')
        : 'OK'

      const modalFooter =
        typeof footer === 'string'
          ? [{ text: footer, onPress: hideDialog }]
          : _.map(footer ? footer.toJS() : [], (item) => {
              return {
                ...item,
                onPress: () => {
                  item.onPress && item.onPress()
                  hideDialog()
                },
              }
            })

      this.modalAlert = Modal.alert(
        <ModalTitle
          title={title}
          onClick={() => {
            this.hideModal()
          }}
          closable={closable}
        />,
        dialogContent,
        hideFooter ? [] : modalFooter,
      )
    }
  }

  hideModal() {
    const { hideDialog } = this.props
    hideDialog()
    this.modalAlert && this.modalAlert.close()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleGlobalTips(this.props, nextProps)
  }

  async UNSAFE_componentWillMount() {
    const {
      initBasicOrderInfo,
      getFingerprint,
      countryCode,
      initLogger,
      checkJumpFromOld,
      languageId,
      getDeviceData,
      getGlobalConfig,
    } = this.props

    // 判断是否从老系统通过uid过来的
    getGlobalConfig()
    checkJumpFromOld()
    initBasicOrderInfo(countryCode, languageId)
    initLogger()
    await initDeviceSDK()
    initTracker()
    getFingerprint()
    getDeviceData()
  }
}

const mapStateToProps = (state) => ({
  deviceType: state.getIn(['main', 'deviceType']),
  loading: state.getIn(['main', 'loading']),
  alertDialog: state.getIn(['main', 'alertDialog']),
  toast: state.getIn(['main', 'toast']),
})

const mapDispatchToProps = (dispatch) => ({
  getDeviceData() {
    dispatch(globalActionCreator.globalAsyncGetDeviceData())
  },
  getGlobalConfig() {
    dispatch(globalActionCreator.globalAsyncGetConfig())
  },
  // checkMerchantWhiteList() {
  //   const { appId } = helpers.URL.allParam()
  //
  //   dispatch(actionCreator.checkMerchantWhiteList(appId))
  // },
  initBasicOrderInfo(countryCode, languageId) {
    const { appId, refNo, sign, token, periods } = helpers.URL.allParam()
    const countryId = countryIdMap[countryCode]
    // 此次设置countryId cookie 主要是给安全组件使用的
    JsCookie.set('countryId', countryId, {
      domain: '.akulaku.com',
    })

    // 这里检测到cookie没有languageId才设置默认的languageId
    if (!JsCookie.get('languageId')) {
      languageId &&
        JsCookie.set('languageId', languageId, {
          domain: '.akulaku.com',
        })
    }

    dispatch(
      actionCreator.goInitBasicOrderInfo({
        appId,
        refNo,
        sign,
        token,
        countryId,
        defaultPeriod: periods ? periods : '', //lazada单独的需求
      }),
    )
  },
  async getFingerprint() {
    getDeviceId().then((deviceId) => {
      helpers.storage.set('deviceId', deviceId)
      dispatch(actionCreator.goAddFingerprint(deviceId))
    })
  },
  initLogger() {
    const { appId, refNo, sign } = helpers.URL.allParam()
    const saLogger = Logger.create({
      openPayAppID: appId,
      openPayOrderID: refNo,
      sign,
    })

    // saLogger.onPageView(window.location.pathname)
    history.listen((location) => {
      // 为什么这里要加settimeout0呢，因为在点击按钮切换路由时应先上报点击事件
      // 再上报pageView事件
      setTimeout(() => {
        const { pathname } = location
        const ignorePathList = ['/orderDetails']

        !ignorePathList.includes(pathname) && saLogger.onPageView()
      }, 0)
    })
  },
  destroyToast() {
    dispatch(globalActionCreator.toggleToast(false, '', null))
  },
  hideDialog() {
    dispatch(
      globalActionCreator.toggleAlertDialog(false, {
        title: '',
        content: '',
        footer: '',
        closable: undefined,
      }),
    )
  },

  checkJumpFromOld() {
    const { fromOld } = helpers.URL.allParam()
    dispatch(actionCreator.goSetJumpFromOld(fromOld === 'true'))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
